import React, { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import arrowInactiveIcon from 'assets/icons/arrow-inactive.svg';
import arrowActiveIcon from 'assets/icons/arrow-active.svg';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './CustomDatePicker.module.scss';
const CustomDatePicker = (props: {
  startDate: any;
  setStartDate: Function;
  endDate: any;
  setEndDate: Function;
  option: any;
}) => {
  const { startDate, setStartDate, endDate, setEndDate, option } = props;
  const [isOpen, setIsOpen] = useState(false);
  const datepickerRef = useRef<any>(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
    datepickerRef.current.setOpen(!isOpen);
  };

  const handleChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className={styles.datepickerWrapper}>
      <button className={`${styles['datepicker-button']} ${isOpen ? styles.active : ''}`} onClick={handleClick}>
        {option.label} <ReactSVG src={isOpen ? arrowActiveIcon : arrowInactiveIcon} />
      </button>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        onClickOutside={handleClick}
        ref={datepickerRef}
        dateFormat="MM/dd/yyyy"
        showPopperArrow={false}
        customInput={<></>}
        selectsRange
      />
    </div>
  );
};

export default CustomDatePicker;
