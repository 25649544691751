import React, { Fragment, useEffect, useState } from 'react';
import styles from './AssetLibrary.module.scss';
import MultiSelectDropdown from 'components/MultiSelectDropdown/MultiSelectDropdown';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CloseIcon from 'assets/icons/close.svg';
import DeleteIcon from 'assets/icons/delete.svg';

import { ReactSVG } from 'react-svg';
import moment from 'moment';
import useRequest from 'hooks/useRequest';
import { enqueueSnackbar } from 'notistack';
import { Loader } from 'components';
import { useSearchParams } from 'react-router-dom';
interface Option {
  label: string;
  value: string;
}
type Asset = {
  data: any[];
  date: string;
};
type Filter = { key: string; label: string; type: string; order: number; values: Option[]; selected: any[] };
const AssetLibrary = () => {
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [searchParams] = useSearchParams();
  const brand = searchParams.get('brand') || 'coca-cola';
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filtersState, setFiltersState] = useState<Filter[]>([]);
  const [chipsState, setChipsState] = useState<Filter[]>([]);
  const [filtersQuery, setFiltersQuery] = useState<Record<string, any>>({});
  const [assets, setAssets] = useState<{ data: Asset[]; metadata: Record<string, any> }>({
    data: [],
    metadata: {},
  });
  const [deleteAssetsBody, setDeleteAssetsBody] = useState<Number[]>([]);
  const [trigger, setTrigger] = useState(false);

  const removeOption = (option: Option) => {
    setFiltersState((prev) =>
      prev.map((filter) => ({
        ...filter,
        selected: filter.selected.filter((item: any) => item !== option),
      }))
    );
    setChipsState((prev) =>
      prev.map((filter) => ({
        ...filter,
        selected: filter.selected.filter((item: any) => item !== option),
      }))
    );
    setOffset(0);
  };

  const { request: getAssetLibraryItems, pending: getAssetLibraryItemsPending } = useRequest({
    url: '/create/assets',
    method: 'get',
    queryParams: filtersQuery,

    onSuccess: (response) => {
      let groupedAssets = Object.values(
        response.data?.data?.reduce((acc: any, asset: any) => {
          asset.date = moment(asset.createdAt).format('DD/MM/YYYY');
          if (!acc[asset.date]) {
            acc[asset.date] = { date: asset.date, data: [] };
          }
          acc[asset.date].data.push(asset);
          return acc;
        }, {} as Asset[])
      ) as Asset[];
      if (filtersQuery.offset !== 0) {
        const mergedData: { [key: string]: Asset } = {};
        const dataArray = [...assets.data, ...groupedAssets];
        dataArray.forEach((entry) => {
          if (!mergedData[entry.date]) {
            mergedData[entry.date] = { date: entry.date, data: [] };
          }
          mergedData[entry.date].data.push(...entry.data);
        });
        groupedAssets = Object.values(mergedData);
      }
      setAssets({ data: groupedAssets, metadata: response.data?.metadata });
    },
  });
  const { request: getAssetLibraryFilters, pending: getAssetLibraryFiltersPending } = useRequest({
    url: '/create/assets/filters',
    method: 'get',
    onSuccess: (response) => {
      response.data.push({
        key: 'Brand',
        label: 'Brand',
        type: 'dropdown',
        order: 0,
        values: [
          {
            label: 'Coca cola',
            value: 'coca-cola',
          },
          {
            label: 'Fanta',
            value: 'fanta',
          },
        ],
      });
      const tempFilters = response.data.map((filter: any) => ({
        ...filter,
        selected: filter.key === 'Brand' ? [brand] : [],
      }));
      setFiltersState(tempFilters);
      setChipsState(tempFilters);
    },
  });
  const { request: deleteAssets } = useRequest({
    url: '/create/assets',
    method: 'delete',
    body: { ids: deleteAssetsBody },
    onSuccess: () => {
      enqueueSnackbar('Asset deleted successfully', {
        variant: 'success',
      });
      setDeleteAssetsBody([]);
      getAssetLibraryItems();
    },
  });
  const loadMore = () => {
    setOffset(offset + limit);
    setFiltersQuery((prev) => ({
      ...prev,
      offset: offset + limit,
      limit,
    }));
  };
  useEffect(() => {
    getAssetLibraryFilters();
  }, [brand]);
  useEffect(() => {
    if (deleteAssetsBody.length > 0) {
      deleteAssets();
    }
  }, [deleteAssetsBody]);

  useEffect(() => {
    // if (filtersState.length === 0) return;
    setFiltersQuery((prev) => ({
      ...prev,
      offset: 0,
      limit,
      ...filtersState.reduce((acc: Record<string, any>, filter) => {
        if (filter.type === 'dropdown') {
          acc[filter.key] = filter.selected;
        }
        return acc;
      }, {}),
    }));
  }, [filtersState]);
  useEffect(() => {
    if (startDate && endDate) {
      setFiltersQuery((prev) => ({
        ...prev,
        dateRange: JSON.stringify([new Date(startDate).getTime(), new Date(endDate).getTime()]),
      }));
    } else {
      if (filtersQuery.dateRange) {
        setFiltersQuery((prev) => {
          delete prev.dateRange;
          return prev;
        });
        setTrigger(!trigger);
      }
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (filtersQuery.limit) {
      getAssetLibraryItems();
    }
  }, [filtersQuery, trigger, brand]);
  return (
    <div>
      <section className={styles.generatedAssets}>
        <h2 className={styles.heading}>Your Generated Assets</h2>
        <div className={styles.card}>
          <div className={styles.filters}>
            <div>
              {filtersState
                .sort((a, b) => a.order - b.order)
                .map((option, index) =>
                  option.type === 'dropdown' ? (
                    <MultiSelectDropdown
                      key={index}
                      option={option}
                      setFiltersState={setFiltersState}
                      filtersState={filtersState}
                      getAssetLibraryItems={getAssetLibraryItems}
                      chipsState={chipsState}
                      setChipsState={setChipsState}
                    />
                  ) : (
                    <CustomDatePicker
                      key={index}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      option={option}
                    />
                  )
                )}
            </div>
            <p>
              Total {assets.metadata.total} {assets.metadata.total <= 1 ? 'Item' : 'Items'}
            </p>
          </div>
          <div className={styles.chipContainer}>
            {startDate && endDate && (
              <div className={styles.chip}>
                <p className={styles.chipValue}>
                  {`${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`}
                </p>
                <span
                  className={styles.closeIcon}
                  onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                  }}
                >
                  <ReactSVG src={CloseIcon} />
                </span>
              </div>
            )}
            {filtersState.map((option) =>
              option.selected.map((item: any) => (
                <div key={item} className={styles.chip}>
                  <p className={styles.chipValue}>{item}</p>
                  {option.key !== 'Brand' && (
                    <span className={styles.closeIcon} onClick={() => removeOption(item)}>
                      <ReactSVG src={CloseIcon} />
                    </span>
                  )}
                </div>
              ))
            )}
          </div>
          {getAssetLibraryFiltersPending ? (
            <Loader />
          ) : (
            <>
              <div className={styles.assetsList}>
                {assets.data.map((asset, key) =>
                  asset.data.map((item, index) => (
                    <Fragment key={index}>
                      <div
                        className={`${key === 0 && index === 0 ? styles.images : index === 0 ? styles.imagesDate : styles.images}`}
                      >
                        {index === 0 && (
                          <h3 className={`${key === 0 && index === 0 ? styles.dateInitial : styles.date}`}>
                            {asset.date}
                          </h3>
                        )}
                        <img
                          src={item.thumbnailUri}
                          alt="Generated Asset"
                          className={`${key === 0 && index === 0 ? styles.imageInitial : index === 0 ? styles.imageDate : styles.image}`}
                        />
                        <div className={styles['delete-icon']} onClick={() => setDeleteAssetsBody([item.id])}>
                          <ReactSVG src={DeleteIcon} />
                        </div>
                      </div>
                    </Fragment>
                  ))
                )}
              </div>
              {offset + limit >= assets.metadata.total ? null : (
                <button className={styles.loadMore} disabled={getAssetLibraryItemsPending} onClick={loadMore}>
                  {getAssetLibraryItemsPending ? 'Loading...' : 'Load More'}
                </button>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default AssetLibrary;
