/* eslint-disable no-unused-vars */
export enum AI {
  DALLE = 'dalle',
  STABILITY = 'stability',
}

export enum FilterType {
  PRODUCT = 'product',
  REGION = 'region',
  AI_TYPE = 'AIType',
  MEAL_TYPE = 'mealType',
  LABEL = 'label',
  BRAND = 'brand',
}
