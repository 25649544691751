import React, { ReactNode, useEffect, useRef } from 'react';
import styles from './Popup.module.scss';
import { ReactSVG } from 'react-svg';
import CloseIcon from '../../assets/icons/close_white.svg';
const Popup = (props: { children: ReactNode; setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const modal = useRef<any>();
  useEffect(() => {
    const handler = (event: Event) => {
      if (!modal.current) {
        return;
      }

      if (!modal.current.contains(event.target)) {
        props.setModalIsOpen(false);
      }
    };

    document.addEventListener('click', handler, true);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);
  return (
    <div className={styles.popup}>
      <div className={styles.popup_inner} ref={modal}>
        <button className={styles.close} onClick={() => props.setModalIsOpen(false)}>
          <ReactSVG src={CloseIcon} />
        </button>
        {props.children}
      </div>
    </div>
  );
};

export default Popup;
