import React, { useEffect, useState } from 'react';
import styles from './ProductLightSettings.module.scss';
import { Filters } from 'components/RightSidebar/RightSidebar';
import GlobalStrings from 'utils/globalStrings';
import ReactSlider from 'react-slider';
import classNames from 'classnames';

const renderThumb = (props: any, state: any) => (
  <div {...props}>
    <div className={styles.current_value}>{state.valueNow}</div>
  </div>
);

const GenerateSliders = (props: {
  data: {
    id: string;
    label: string;
    renderThumb: (props: any, state: any) => React.JSX.Element;
    onChange: (newValue: any) => void;
    value: number;
    min: number;
    max: number;
    step: number;
    marks: number[];
    renderMark: (props: any) => React.JSX.Element;
    withNumbers: boolean;
  }[];
  label?: string;
  children?: React.JSX.Element;
}) => {
  return (
    <div className={styles.inner_container}>
      {props.label && <p className={styles.slider_label}>{props.label}</p>}
      {props.children}
      {props.data.map((item) => {
        return (
          <div key={item.id}>
            <p className={styles.slider_label}>{item.label}</p>

            <div
              className={classNames(styles.slider_container, {
                [styles.with_numbers]: item.withNumbers,
              })}
            >
              <ReactSlider
                className={styles.slider}
                thumbClassName={styles.thumb}
                thumbActiveClassName={styles.active_thumb}
                trackClassName={styles.track}
                markClassName={styles.mark}
                renderThumb={item.renderThumb}
                onChange={item.onChange}
                value={item.value}
                min={item.min}
                max={item.max}
                step={item.step}
                marks={item.marks}
                renderMark={item.renderMark}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ProductLightSettings = (props: {
  filters: Filters | undefined;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | Function;
  dataProductLightSettings: any;
}) => {
  const [productLightsRotation, setProductLightsRotation] = useState(
    props.filters?.productLightsSettings?.productLightsRotation
  );
  const [productLightLeftExposure, setProductLightLeftExposure] = useState(
    props.filters?.productLightsSettings?.productLightLeftExposure
  );
  const [productLightLeftTemperature, setProductLightLeftTemperature] = useState(
    props.filters?.productLightsSettings?.productLightLeftTemperature
  );
  const [productLightRightExposure, setProductLightRightExposure] = useState(
    props.filters?.productLightsSettings?.productLightRightExposure
  );
  const [productLightRightTemperature, setProductLightRightTemperature] = useState(
    props.filters?.productLightsSettings?.productLightRightTemperature
  );
  const [productLightFrontExposure, setProductLightFrontExposure] = useState(
    props.filters?.productLightsSettings?.productLightFrontExposure
  );
  const [productLightFrontTemperature, setProductLightFrontTemperature] = useState(
    props.filters?.productLightsSettings?.productLightFrontTemperature
  );
  const [productLightRearExposure, setProductLightRearExposure] = useState(
    props.filters?.productLightsSettings?.productLightRearExposure
  );
  const [productLightRearTemperature, setProductLightRearTemperature] = useState(
    props.filters?.productLightsSettings?.productLightRearTemperature
  );
  const [productLightTopExposure, setProductLightTopExposure] = useState(
    props.filters?.productLightsSettings?.productLightTopExposure
  );
  const [productLightTopTemperature, setProductLightTopTemperature] = useState(
    props.filters?.productLightsSettings?.productLightTopTemperature
  );
  const productLightsRotationOptions = props.dataProductLightSettings?.rotation
    ? [
        {
          id: 'rotation-1',
          label: props.dataProductLightSettings.rotation.label,
          renderThumb,
          onChange: (newValue: any) => setProductLightsRotation(newValue),
          value: productLightsRotation ?? 0,
          min: props.dataProductLightSettings.rotation.min,
          max: props.dataProductLightSettings.rotation.max,
          step: 1,
          marks: [props.dataProductLightSettings.rotation.min, props.dataProductLightSettings.rotation.max],
          renderMark: (props: any) => {
            const order = props?.key;
            return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
          },
          withNumbers: true,
        },
      ]
    : null;

  const productLightLeftOptions = [
    ...(props.dataProductLightSettings?.left_exposure
      ? [
          {
            id: 'left-1',
            label: props.dataProductLightSettings.left_exposure.label,
            renderThumb,
            step: 0.1,
            onChange: (newValue: any) => setProductLightLeftExposure(newValue),
            value: productLightLeftExposure ?? 0,
            min: props.dataProductLightSettings.left_exposure.min,
            max: props.dataProductLightSettings.left_exposure.max,
            marks: [props.dataProductLightSettings.left_exposure.min, props.dataProductLightSettings.left_exposure.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 1.5 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataProductLightSettings?.left_temperature
      ? [
          {
            id: 'left-2',
            label: props.dataProductLightSettings.left_temperature.label,
            renderThumb,
            step: 1,
            onChange: (newValue: any) => setProductLightLeftTemperature(newValue),
            value: productLightLeftTemperature ?? 0,
            min: props.dataProductLightSettings.left_temperature.min,
            max: props.dataProductLightSettings.left_temperature.max,
            marks: [
              props.dataProductLightSettings.left_temperature.min,
              props.dataProductLightSettings.left_temperature.max,
            ],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
  ];

  const productLightRightOptions = [
    ...(props.dataProductLightSettings?.right_exposure
      ? [
          {
            id: 'right-1',
            label: props.dataProductLightSettings.right_exposure.label,
            renderThumb,
            step: 0.1,
            onChange: (newValue: any) => setProductLightRightExposure(newValue),
            value: productLightRightExposure ?? 0,
            min: props.dataProductLightSettings.right_exposure.min,
            max: props.dataProductLightSettings.right_exposure.max,
            marks: [
              props.dataProductLightSettings.right_exposure.min,
              props.dataProductLightSettings.right_exposure.max,
            ],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 1.5 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataProductLightSettings?.right_temperature
      ? [
          {
            id: 'right-2',
            label: props.dataProductLightSettings.right_temperature.label,
            renderThumb,
            step: 1,
            onChange: (newValue: any) => setProductLightRightTemperature(newValue),
            value: productLightRightTemperature ?? 0,
            min: props.dataProductLightSettings.right_temperature.min,
            max: props.dataProductLightSettings.right_temperature.max,
            marks: [
              props.dataProductLightSettings.right_temperature.min,
              props.dataProductLightSettings.right_temperature.max,
            ],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
  ];

  const productLightFrontOptions = [
    ...(props.dataProductLightSettings?.front_exposure
      ? [
          {
            id: 'front-1',
            label: props.dataProductLightSettings.front_exposure.label,
            renderThumb,
            step: 0.1,
            onChange: (newValue: any) => setProductLightFrontExposure(newValue),
            value: productLightFrontExposure ?? 0,
            min: props.dataProductLightSettings.front_exposure.min,
            max: props.dataProductLightSettings.front_exposure.max,
            marks: [
              props.dataProductLightSettings.front_exposure.min,
              props.dataProductLightSettings.front_exposure.max,
            ],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 1.5 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataProductLightSettings?.front_temperature
      ? [
          {
            id: 'front-2',
            label: props.dataProductLightSettings.front_temperature.label,
            renderThumb,
            step: 1,
            onChange: (newValue: any) => setProductLightFrontTemperature(newValue),
            value: productLightFrontTemperature ?? 0,
            min: props.dataProductLightSettings.front_temperature.min,
            max: props.dataProductLightSettings.front_temperature.max,
            marks: [
              props.dataProductLightSettings.front_temperature.min,
              props.dataProductLightSettings.front_temperature.max,
            ],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
  ];

  const productLightRearOptions = [
    ...(props.dataProductLightSettings?.rear_exposure
      ? [
          {
            id: 'rear-1',
            label: props.dataProductLightSettings.rear_exposure.label,
            renderThumb,
            step: 1,
            onChange: (newValue: any) => setProductLightRearExposure(newValue),
            value: productLightRearExposure ?? 0,
            min: props.dataProductLightSettings.rear_exposure.min,
            max: props.dataProductLightSettings.rear_exposure.max,
            marks: [props.dataProductLightSettings.rear_exposure.min, props.dataProductLightSettings.rear_exposure.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataProductLightSettings?.rear_temperature
      ? [
          {
            id: 'rear-2',
            label: props.dataProductLightSettings.rear_temperature.label,
            renderThumb,
            step: 1,
            onChange: (newValue: any) => setProductLightRearTemperature(newValue),
            value: productLightRearTemperature ?? 0,
            min: props.dataProductLightSettings.rear_temperature.min,
            max: props.dataProductLightSettings.rear_temperature.max,
            marks: [
              props.dataProductLightSettings.rear_temperature.min,
              props.dataProductLightSettings.rear_temperature.max,
            ],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
  ];

  const productLightTopOptions = [
    ...(props.dataProductLightSettings?.top_exposure
      ? [
          {
            id: 'top-1',
            label: props.dataProductLightSettings.top_exposure.label,
            renderThumb,
            step: 1,
            onChange: (newValue: any) => setProductLightTopExposure(newValue),
            value: productLightTopExposure ?? 0,
            min: props.dataProductLightSettings.top_exposure.min,
            max: props.dataProductLightSettings.top_exposure.max,
            marks: [props.dataProductLightSettings.top_exposure.min, props.dataProductLightSettings.top_exposure.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataProductLightSettings?.top_temperature
      ? [
          {
            id: 'top-2',
            label: props.dataProductLightSettings.top_temperature.label,
            renderThumb,
            step: 1,
            onChange: (newValue: any) => setProductLightTopTemperature(newValue),
            value: productLightTopTemperature ?? 0,
            min: props.dataProductLightSettings.top_temperature.min,
            max: props.dataProductLightSettings.top_temperature.max,
            marks: [
              props.dataProductLightSettings.top_temperature.min,
              props.dataProductLightSettings.top_temperature.max,
            ],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order - 150 : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
  ];

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightsRotation,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightsRotation]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightLeftExposure,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightLeftExposure]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightLeftTemperature,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightLeftTemperature]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightRightExposure,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightRightExposure]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightRightTemperature,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightRightTemperature]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightFrontExposure,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightFrontExposure]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightFrontTemperature,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightFrontTemperature]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightRearExposure,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightRearExposure]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightRearTemperature,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightRearTemperature]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightTopExposure,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightTopExposure]);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => ({
        ...prev,
        productLightsSettings: {
          ...prev?.productLightsSettings,
          productLightTopTemperature,
        },
      }));
    }, 700);

    return () => clearTimeout(delay);
  }, [productLightTopTemperature]);

  const resetHandler = () => {
    setProductLightsRotation(props.dataProductLightSettings.rotation?.default);
    setProductLightLeftExposure(props.dataProductLightSettings.left_exposure?.default);
    setProductLightLeftTemperature(props.dataProductLightSettings.left_temperature?.default);
    setProductLightRightExposure(props.dataProductLightSettings.right_exposure?.default);
    setProductLightRightTemperature(props.dataProductLightSettings.right_temperature?.default);
    setProductLightFrontExposure(props.dataProductLightSettings.front_exposure?.default);
    setProductLightFrontTemperature(props.dataProductLightSettings.front_temperature?.default);
    setProductLightRearExposure(props.dataProductLightSettings.rear_exposure?.default);
    setProductLightRearTemperature(props.dataProductLightSettings.rear_temperature?.default);
    setProductLightTopExposure(props.dataProductLightSettings.top_exposure?.default);
    setProductLightTopTemperature(props.dataProductLightSettings.top_temperature?.default);
    props.setFilters((prev: any) => {
      return {
        ...prev,
        productLightSettings: {
          productLightsRotation: props.dataProductLightSettings.rotation?.default,
          productLightLeftExposure: props.dataProductLightSettings.left_exposure?.default,
          productLightLeftTemperature: props.dataProductLightSettings.left_temperature?.default,
          productLightRightExposure: props.dataProductLightSettings.right_exposure?.default,
          productLightRightTemperature: props.dataProductLightSettings.right_temperature?.default,
          productLightFrontExposure: props.dataProductLightSettings.front_exposure?.default,
          productLightFrontTemperature: props.dataProductLightSettings.front_temperature?.default,
          productLightRearExposure: props.dataProductLightSettings.rear_exposure?.default,
          productLightRearTemperature: props.dataProductLightSettings.rear_temperature?.default,
          productLightTopExposure: props.dataProductLightSettings.top_exposure?.default,
          productLightTopTemperature: props.dataProductLightSettings.top_temperature?.default,
        },
      };
    });
  };

  return (
    <div className={styles.container}>
      {productLightsRotationOptions && (
        <GenerateSliders data={productLightsRotationOptions} label={GlobalStrings['Product Lights Rotation']} />
      )}
      {productLightLeftOptions && (
        <GenerateSliders data={productLightLeftOptions} label={GlobalStrings['Left Product Lights']} />
      )}
      {productLightRightOptions && (
        <GenerateSliders data={productLightRightOptions} label={GlobalStrings['Right Product Lights']} />
      )}
      {productLightFrontOptions && (
        <GenerateSliders data={productLightFrontOptions} label={GlobalStrings['Front Product Lights']} />
      )}
      {productLightRearOptions && (
        <GenerateSliders data={productLightRearOptions} label={GlobalStrings['Rear Product Lights']} />
      )}
      {productLightTopOptions && (
        <GenerateSliders data={productLightTopOptions} label={GlobalStrings['Top Product Lights']} />
      )}
      <div className={styles.buttons_container}>
        <button className={styles.btn} onClick={resetHandler}>
          {GlobalStrings.Reset}
        </button>
        <button className={styles.btn} onClick={() => props.setIsOpen(false)}>
          {GlobalStrings.OK}
        </button>
      </div>
    </div>
  );
};

export default ProductLightSettings;
