import React, { useState, useRef, useEffect } from 'react';
import styles from './MultiSelectDropdown.module.scss';
import { ReactSVG } from 'react-svg';
import searchIcon from 'assets/icons/search.svg';
import arrowInactiveIcon from 'assets/icons/arrow-inactive.svg';
import arrowActiveIcon from 'assets/icons/arrow-active.svg';
import { useNavigate } from 'react-router-dom';

const MultiSelectDropdown = (props: {
  option: any;
  filtersState: any;
  setFiltersState: any;
  getAssetLibraryItems: any;
  chipsState: any;
  setChipsState: any;
}) => {
  const { option, setFiltersState, chipsState, setChipsState } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const toggleSelection = (item: any) => {
    setChipsState((prev: any) => {
      const updatedFilters = prev.map((filter: any) => {
        if (filter.key === option.key) {
          if (filter.key === 'Brand') {
            return {
              ...filter,
              selected: [item],
            };
          }
          return {
            ...filter,
            selected: filter.selected.includes(item)
              ? filter.selected.filter((i: any) => i !== item)
              : [...filter.selected, item],
          };
        }
        return filter;
      });
      return updatedFilters;
    });
  };
  const clearSelection = () => {
    setFiltersState((prev: any) => {
      const updatedFilters = prev.map((filter: any) => {
        if (filter.key === option.key) {
          return { ...filter, selected: [] };
        }
        return filter;
      });
      return updatedFilters;
    });
    setChipsState((prev: any) => {
      const updatedFilters = prev.map((filter: any) => {
        if (filter.key === option.key) {
          return { ...filter, selected: [] };
        }
        return filter;
      });
      return updatedFilters;
    });
  };

  const handleApply = (option: any) => {
    if (option === 'Brand') {
      navigate(`/asset-library?brand=${chipsState.find((val: any) => val.key === option)?.selected[0]}`);
    }
    setIsOpen(false);
    if (option !== 'Brand') {
      setFiltersState(chipsState);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={styles['multi-select-dropdown']} ref={dropdownRef}>
      <button className={`${styles['dropdown-btn']} ${isOpen ? styles.active : ''}`} onClick={() => setIsOpen(!isOpen)}>
        {option.label} <ReactSVG src={isOpen ? arrowActiveIcon : arrowInactiveIcon} />
      </button>

      {isOpen && (
        <div className={styles['dropdown-menu']}>
          <div className={styles['search-container']}>
            <input
              type="text"
              placeholder="Search"
              className={styles['search-input']}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ReactSVG src={searchIcon} className={styles['search-icon']} />
          </div>

          <div className={styles['options-list']}>
            {option.values
              .filter((item: any) => item.value.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((item: any) => (
                <div key={item.value} className={styles.option} onClick={() => toggleSelection(item.value)}>
                  <input
                    type="checkbox"
                    checked={chipsState.find((val: any) => val.key === option.key)?.selected.includes(item.value)}
                    readOnly
                  />
                  <span>{item.label}</span>
                </div>
              ))}
          </div>

          <button className={styles['apply-btn']} onClick={() => handleApply(option.key)}>
            Apply
          </button>
          <div className={styles.footer}>
            <span>{chipsState.find((val: any) => val.key === option.key)?.selected.length} Selected</span>
            {option.key !== 'Brand' && (
              <button className={styles['clear-btn']} onClick={clearSelection}>
                Clear selected
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default MultiSelectDropdown;
