import React, { useEffect, useMemo, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import styles from './RightSidebar.module.scss';
import cameraSettingsIcon from 'assets/icons/camera_settings_icon.svg';
import lightSettingsIcon from 'assets/icons/light_settings_icon.svg';
import productLightSettingsIcon from 'assets/icons/productLightsSettings.svg';
import { ReactSVG } from 'react-svg';
import TooltipPopup from 'components/TooltipPopup/TooltipPopup';
import GlobalStrings from 'utils/globalStrings';
import CameraSettings from 'components/CameraSettings/CameraSettings';
import LightSettings from 'components/LightSettings/LightSettings';
import ProductLightSettings from 'components/ProductLightSettings/ProductLightSettings';
import useRequest from 'hooks/useRequest';
import Loader from 'components/Loader/Loader';
import classNames from 'classnames';
import { useOktaAuth } from '@okta/okta-react';
import { saveAs } from 'file-saver';
import { getFromLocalStorage, checkTenantId, generateSeed } from '../../utils/globalFunctions';
import { useSearchParams } from 'react-router-dom';
import Popup from 'components/Popup/Popup';
export interface ProductLightsSettings {
  productLightsRotation?: number;
  productLightLeftExposure?: number;
  productLightLeftTemperature?: number;
  productLightRightExposure?: number;
  productLightRightTemperature?: number;
  productLightFrontExposure?: number;
  productLightFrontTemperature?: number;
  productLightRearExposure?: number;
  productLightRearTemperature?: number;
  productLightTopExposure?: number;
  productLightTopTemperature?: number;
}
export interface CamerasSettings {
  rotation: number;
  angles: { value: string; selected: boolean; available: boolean }[];
}
export interface LightsSettings {
  height: number;
  y_rotation: number;
  hdr_exposure: number;
  key_exposure: number;
  hdr_temperature: number;
  key_temperature: number;
  hdri_file_name: { label: string; default: any; options: any[] };
}

export type Filters = {
  dressing: string;
  cameraSettings?: CamerasSettings;
  lightSettings?: LightsSettings;
  productLightsSettings?: ProductLightsSettings;
};

export interface ErrorResponse {
  message: string;
  statusCode: number;
}
interface AxiosError {
  response: {
    data: ErrorResponse;
  };
}
interface DataExport {
  product_name: string;
  region_name: string;
  product_label: string;
  ai_type: string;
  meal_type: string;
  prompt: string;
  negative_prompt: string;
  timestamp: string;
  sdxl_detailer?: string;
}
const RightSidebar = (props: {
  imageURL: string;
  assetFileUrl: string;
  setImageURL: React.Dispatch<React.SetStateAction<string>>;
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  exportModalIsOpen: Boolean;
  setExportModalIsOpen: React.Dispatch<React.SetStateAction<any>>;
  dataExport: DataExport;
  accentColor: string;
  retouchDisabled: boolean;
  dataProductLightSettings: any;
  dataLightSettings: any;
  dataCameraSettings: any;
}) => {
  const [isOpen, setIsOpen] = useState<{
    dressing: boolean;
    cameraSettings: boolean;
    lightSettings: boolean;
    productLightSettings: boolean;
  }>({
    dressing: false,
    cameraSettings: false,
    lightSettings: false,
    productLightSettings: false,
  });
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const user = getFromLocalStorage('user');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [retouchValue, setRetouchValue] = useState('');
  const [downloadSuccessIsOpen, setDownloadSuccessIsOpen] = useState(false);
  const [downloadSuccessText, setDownloadSuccessText] = useState('');
  const [searchParams] = useSearchParams();
  const brand = searchParams.get('brand');

  const tenantId = useMemo(() => {
    return checkTenantId(brand);
  }, [brand]);
  const { pending: dressingPending } = useRequest({
    url: '/omniverse/setdressing-controls/set-dressing',
    method: 'post',
  });

  const { request: setCameraAngle, pending: cameraAnglePending } = useRequest({
    url: '/omniverse/camera-controls/set-camera-angle',
    method: 'post',
  });

  const { request: setCameraHDRIRotation, pending: cameraHDRIRotationPending } = useRequest({
    url: '/omniverse/hdri/set-hdr-rotation',
    method: 'post',
  });

  const { request: setExposure, pending: exposurePending } = useRequest({
    url: '/omniverse/light-controls/set-light-exposure',
    method: 'post',
  });

  const { request: setTemperature, pending: temperaturePending } = useRequest({
    url: '/omniverse/light-controls/set-light-temperature',
    method: 'post',
  });

  const { request: setLightAngle, pending: lightAnglePending } = useRequest({
    url: '/omniverse/light-controls/pivot-light-rig',
    method: 'post',
  });
  const { request: updateHdri, pending: hdriPending } = useRequest({
    url: '/omniverse/hdri/update-hdri',
    method: 'post',
  });
  const { request: setProductLightsRotation, pending: rotationPending } = useRequest({
    url: '/omniverse/product-lights/rotation',
    method: 'put',
  });
  const { request: setProductLightExposure, pending: productExposurePending } = useRequest({
    url: '/omniverse/product-lights',
    method: 'put',
  });
  const updateProductLightExposure = (key: string, exposureValue: any) => {
    const body = { exposure: exposureValue };
    setProductLightExposure({ url: `/omniverse/product-lights/${key}/exposure`, body });
  };
  const { request: setProductLightTemperature, pending: productTemperaturePending } = useRequest({
    url: '/omniverse/product-lights',
    method: 'put',
  });
  const updateProductLightTemperature = (key: string, exposureValue: any) => {
    const body = { temperature: exposureValue };
    setProductLightTemperature({ url: `/omniverse/product-lights/${key}/temperature`, body });
  };

  const pending =
    dressingPending ||
    cameraAnglePending ||
    cameraHDRIRotationPending ||
    exposurePending ||
    lightAnglePending ||
    rotationPending ||
    productExposurePending ||
    productTemperaturePending ||
    hdriPending ||
    temperaturePending;
  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightsRotation === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      const body = {
        yaw: props.filters?.productLightsSettings?.productLightsRotation,
      };
      setProductLightsRotation({ body });
    }
  }, [props.filters?.productLightsSettings?.productLightsRotation, props.dataProductLightSettings]);
  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightLeftExposure === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightExposure('left', props.filters?.productLightsSettings?.productLightLeftExposure);
    }
  }, [props.filters?.productLightsSettings?.productLightLeftExposure, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightRightExposure === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightExposure('right', props.filters?.productLightsSettings?.productLightRightExposure);
    }
  }, [props.filters?.productLightsSettings?.productLightRightExposure, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightFrontExposure === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightExposure('front', props.filters?.productLightsSettings?.productLightFrontExposure);
    }
  }, [props.filters?.productLightsSettings?.productLightFrontExposure, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightRearExposure === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightExposure('rear', props.filters?.productLightsSettings?.productLightRearExposure);
    }
  }, [props.filters?.productLightsSettings?.productLightRearExposure, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightTopExposure === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightExposure('top', props.filters?.productLightsSettings?.productLightTopExposure);
    }
  }, [props.filters?.productLightsSettings?.productLightTopExposure, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightLeftTemperature === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightTemperature('left', props.filters?.productLightsSettings?.productLightLeftTemperature);
    }
  }, [props.filters?.productLightsSettings?.productLightLeftTemperature, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightRightTemperature === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightTemperature('right', props.filters?.productLightsSettings?.productLightRightTemperature);
    }
  }, [props.filters?.productLightsSettings?.productLightRightTemperature, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightFrontTemperature === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightTemperature('front', props.filters?.productLightsSettings?.productLightFrontTemperature);
    }
  }, [props.filters?.productLightsSettings?.productLightFrontTemperature, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightRearTemperature === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightTemperature('rear', props.filters?.productLightsSettings?.productLightRearTemperature);
    }
  }, [props.filters?.productLightsSettings?.productLightRearTemperature, props.dataProductLightSettings]);

  useEffect(() => {
    if (
      typeof props.filters?.productLightsSettings?.productLightTopTemperature === 'number' &&
      brand &&
      props.dataProductLightSettings
    ) {
      updateProductLightTemperature('top', props.filters?.productLightsSettings?.productLightTopTemperature);
    }
  }, [props.filters?.productLightsSettings?.productLightTopTemperature, props.dataProductLightSettings]);
  useEffect(() => {
    if (props.filters?.cameraSettings?.angles) {
      const selectedAngle = props.filters?.cameraSettings?.angles.find((angle) => angle.selected)?.value;

      if (selectedAngle && brand) {
        const body = {
          angle: selectedAngle.toLowerCase(),
        };
        setCameraAngle({ body });
      }
    }
  }, [props.filters?.cameraSettings?.angles, brand]);
  useEffect(() => {
    if (props.filters?.cameraSettings?.rotation) {
      const HDRIRotation = props.filters?.cameraSettings?.rotation;

      if (typeof HDRIRotation === 'number' && brand) {
        const body = {
          y_rotation: HDRIRotation,
        };
        setCameraHDRIRotation({ body });
      }
    }
  }, [props.filters?.cameraSettings?.rotation, brand]);

  useEffect(() => {
    if (
      props.filters?.lightSettings &&
      (props.filters.lightSettings.hdr_exposure >= 0 || props.filters.lightSettings.key_exposure >= 0) &&
      brand
    ) {
      const body = {
        key_exposure: props.filters.lightSettings.key_exposure,
        hdr_exposure: props.filters.lightSettings.hdr_exposure,
      };
      setExposure({ body });
    }
  }, [props.filters?.lightSettings?.key_exposure, props.filters?.lightSettings?.hdr_exposure, brand]);

  useEffect(() => {
    if (
      props.filters?.lightSettings &&
      (props.filters.lightSettings.key_temperature >= 0 || props.filters.lightSettings.hdr_temperature >= 0) &&
      brand
    ) {
      const body = {
        key_temperature: props.filters.lightSettings.key_temperature,
        hdr_temperature: props.filters.lightSettings.hdr_temperature,
      };
      setTemperature({ body });
    }
  }, [props.filters?.lightSettings?.key_temperature, props.filters?.lightSettings?.hdr_temperature, brand]);

  useEffect(() => {
    if (
      props.filters?.lightSettings &&
      (props.filters.lightSettings.height >= 0 || props.filters.lightSettings.y_rotation >= 0) &&
      brand
    ) {
      const body = {
        y_rotation: props.filters.lightSettings.y_rotation,
        height: props.filters.lightSettings.height,
      };
      setLightAngle({ body });
    }
  }, [props.filters?.lightSettings?.height, props.filters?.lightSettings?.y_rotation, brand]);
  useEffect(() => {
    const options = props.filters?.lightSettings?.hdri_file_name?.options || [];
    const selectedOption = options.find((h) => h.selected);

    if (selectedOption?.value && brand) {
      const body = {
        hdri_file_name: selectedOption.value,
      };
      updateHdri({ body });
    }
  }, [props.filters?.lightSettings?.hdri_file_name, brand]);

  type MenuItem = {
    id: number;
    name: string;
    label: string;
    image: string;
    component: React.JSX.Element;
    tooltipMessage: string;
    onClick?: () => void;
  };

  const menuItems: MenuItem[] = [
    {
      id: 1,
      name: 'cameraSettings',
      label: GlobalStrings['Camera Settings'],
      image: cameraSettingsIcon,
      component: (
        <CameraSettings
          filters={props.filters}
          setFilters={props.setFilters}
          setIsOpen={(value: boolean) => popupHandler('cameraSettings', value)}
          dataCameraSettings={props.dataCameraSettings}
        />
      ),
      tooltipMessage:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    },
    {
      id: 2,
      name: 'lightSettings',
      label: GlobalStrings['Light Settings'],
      image: lightSettingsIcon,
      component: (
        <LightSettings
          filters={props.filters}
          setFilters={props.setFilters}
          setIsOpen={(value: boolean) => popupHandler('lightSettings', value)}
          dataLightSettings={props.dataLightSettings}
        />
      ),
      tooltipMessage:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    },
  ];
  if (props.dataProductLightSettings !== null) {
    menuItems.push({
      id: 3,
      name: 'productLightSettings',
      label: GlobalStrings['Product Light Settings'],
      image: productLightSettingsIcon,
      component: (
        <ProductLightSettings
          dataProductLightSettings={props.dataProductLightSettings}
          filters={props.filters}
          setFilters={props.setFilters}
          setIsOpen={(value: boolean) => popupHandler('productLightSettings', value)}
        />
      ),
      tooltipMessage:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
    });
  }
  const popupHandler = (name: any, value: any) => {
    setIsOpen({
      dressing: false,
      cameraSettings: false,
      lightSettings: false,
      productLightSettings: false,
      [name]: value,
    });
  };
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: user ? `Bearer ${getFromLocalStorage('user')?.acccesToken}` : `Bearer ${accessToken}`,
      context: JSON.stringify(tenantId),
    },
  });

  const captureLowResRequest = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const segments = props.imageURL.split('/');
      const midgroundName = segments[segments.length - 1];
      const bodyRequest = {
        midground_name: midgroundName,
        region_name: props.dataExport.region_name,
        product_name: props.dataExport.product_name,
        product_label: props.dataExport.product_label,
      };
      const responseData = await axiosInstance.post('/omniverse/scene-layers/low-resolution', bodyRequest, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([responseData.data], { type: 'application/zip' });
      const fileName = midgroundName.split('.')[0];
      saveAs(blob, `${fileName}.zip`);
      setDownloadSuccessIsOpen(true);
      setDownloadSuccessText('The low-res image has been downloaded successfully.');
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    } finally {
      setIsLoading(false);
      props.setExportModalIsOpen(false);
    }
  };
  const upscaleImageRequest = async (): Promise<string> => {
    try {
      const bodyRequest = {
        fileName: props.imageURL,
      };
      const responseData = await axiosInstance.post('/omniverse/upscale-image', bodyRequest);
      return responseData.data.output_path;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(axiosError);
      throw axiosError;
    }
  };
  const captureHighResRequest = async (outputPath: string): Promise<void> => {
    try {
      const segments = props.imageURL.split('/');
      const midgroundName = segments[segments.length - 1];
      const bodyRequest = {
        highres_backplate_path: outputPath,
        midground_name: midgroundName,
        region_name: props.dataExport.region_name,
        product_name: props.dataExport.product_name,
        product_label: props.dataExport.product_label,
      };
      const responseData = await axiosInstance.post('/omniverse/scene-layers/high-resolution', bodyRequest, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([responseData.data], { type: 'application/zip' });
      const fileName = midgroundName.split('.')[0];
      saveAs(blob, `${fileName}.zip`);
      setDownloadSuccessIsOpen(true);
      setDownloadSuccessText('The high-res image has been downloaded successfully.');
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(axiosError);
      throw axiosError;
    } finally {
      props.setExportModalIsOpen(false);
    }
  };
  const sendToRetouch = async () => {
    if (retouchValue.trim() === '') {
      alert(GlobalStrings['Optional information cannot be blank']);
    } else {
      const bodyRequest = {
        region_name: props.dataExport.region_name,
        product_name: props.dataExport.product_name,
        product_label: props.dataExport.product_label,
        meal_type: props.dataExport.meal_type,
        prompt: props.dataExport.prompt,
        negative_prompt: props.dataExport.negative_prompt,
        fileName: props.imageURL,
        details: retouchValue,
        generated_at: props.dataExport.timestamp,
        camera_angle: props.filters?.cameraSettings?.angles.find((h: any) => h.selected)?.value ?? 'low',
        sdxl_detailer: props.dataExport.sdxl_detailer,
        seed_base_pass: generateSeed(),
        seed_detailer_pass: generateSeed(),
      };
      try {
        await axiosInstance.post('/create/send-to-retouch', bodyRequest);
        props.setExportModalIsOpen(false);
        setDownloadSuccessIsOpen(true);
        setDownloadSuccessText("We've sent the processed image to your email/DAM.");
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleHighResRequest = async (): Promise<void> => {
    setIsLoading(true);
    try {
      setLoadingMessage(
        GlobalStrings[
          "We are busy upscaling your image, this will take approx 1-2 minutes. Please don't close the window!"
        ]
      );
      const outputPath = await upscaleImageRequest();
      setLoadingMessage(GlobalStrings['Downloaded image(s) will appear in your downloads folder when complete.']);
      await captureHighResRequest(outputPath);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };
  async function downloadImage(resolution: string) {
    try {
      if (resolution === 'low') {
        await captureLowResRequest();
      } else if (resolution === 'high') {
        await handleHighResRequest();
      }
    } catch (err) {
      const downloadImageError = err as ErrorResponse;
      const message = downloadImageError.message;
      console.log(message);
    }
  }
  const handleExportPopup = (resolution: string) => {
    downloadImage(resolution);
  };
  useEffect(() => {}, [authState]);
  useEffect(() => {
    document.documentElement.style.setProperty('--accent-color', props.accentColor);
  }, [props.accentColor]);

  return (
    <div
      className={classNames(styles.right_sidebar, {
        [styles.active]: true,
      })}
    >
      <p>{GlobalStrings.Tools}</p>
      <div className={styles.menu_items}>
        {menuItems.map((item) => {
          return item.component ? (
            <TooltipPopup
              key={item.id}
              headerTitle={item.label}
              position="left top"
              isOpen={isOpen[item.name as 'dressing' | 'cameraSettings' | 'lightSettings' | 'productLightSettings']}
              setIsOpen={(value) => popupHandler(item.name, value)}
              content={item.component}
              tooltipMessage={item?.tooltipMessage}
              trigger={
                <div key={item.id} className={styles.menu_item}>
                  <ReactSVG src={item.image} className={styles.image} />
                  <p>{item.label}</p>
                </div>
              }
            />
          ) : (
            <div
              key={item.id}
              className={classNames(styles.menu_item, {
                [styles.active]: false,
              })}
              onClick={item.onClick}
              aria-hidden="true"
            >
              <ReactSVG src={item.image} className={styles.image} />
              <p>{item.label}</p>
            </div>
          );
        })}
      </div>
      {/* <div className={styles.separator}></div> */}
      {/* <div className={styles.menu_item} onClick={() => setExportModalIsOpen(true)} aria-hidden="true">
        <ReactSVG src={exportIcon} className={styles.image} />
        <p>{GlobalStrings.Export}</p>
      </div> */}
      {props.exportModalIsOpen && (
        <Popup setModalIsOpen={props.setExportModalIsOpen}>
          <h2 className={styles.export_text}>Export Image</h2>
          <div className={styles.content}>
            {props.assetFileUrl && props.assetFileUrl !== '' && (
              <div className={styles.left}>
                <img src={props.assetFileUrl} alt="Asset" className={styles.image} />
              </div>
            )}
            <div className={styles.right}>
              <div className={styles['download-section']}>
                <p>
                  Low Resolution, <span>72 PPI</span>
                </p>
                <button className={styles['download-button']} onClick={() => handleExportPopup('low')}>
                  Download
                </button>
              </div>
              <div className={styles['download-section']}>
                <p>
                  High Resolution, <span>300 PPI</span>
                </p>
                <button className={styles['download-button']} onClick={() => handleExportPopup('high')}>
                  Download
                </button>
              </div>
              <div className={styles['retouching-section']}>
                <p>
                  Asset Retouching <span>(Optional)</span>
                </p>
              </div>
              <div className={styles['textarea-wrapper']}>
                <textarea
                  placeholder="Add some optional information for re-touch"
                  value={retouchValue}
                  onChange={(e) => setRetouchValue(e.target.value)}
                />
                <button className={styles['send-button']} onClick={sendToRetouch}>
                  Send
                </button>
              </div>
              <p className={styles.note}>
                *Your retouched image will be sent to your email/DAM after processing (TAT applies).
              </p>
            </div>
          </div>
        </Popup>
      )}
      {downloadSuccessIsOpen && (
        <Popup setModalIsOpen={setDownloadSuccessIsOpen}>
          <div className={styles.success}>
            <h2>Success</h2>
            <p>{downloadSuccessText}</p>
            <div className={styles.buttonContainer}>
              <button className={styles.okButton} onClick={() => setDownloadSuccessIsOpen(false)}>
                Ok
              </button>
            </div>
          </div>
        </Popup>
      )}
      {isLoading ? <Loader message={loadingMessage} /> : <></>}
      {pending && <Loader />}
    </div>
  );
};

export default RightSidebar;
