import React, { useEffect, useState } from 'react';
import styles from './Filters.module.scss';
import productIcon from 'assets/icons/filters/product_icon.svg';
import labelIcon from 'assets/icons/filters/label_icon.svg';
import brandIcon from 'assets/icons/filters/brand.svg';
import mealTypeIcon from 'assets/icons/filters/meal_type_icon.svg';
import regionIcon from 'assets/icons/filters/location_icon.svg';
import CocaCola from 'assets/icons/filters/coca-cola.png';
import Fanta from 'assets/icons/filters/fanta.png';
import Filter from 'components/Filter/Filter';
import { UseFormReturn } from 'react-hook-form';
import { Inputs } from 'utils/types';
import useRequest from 'hooks/useRequest';
import { AI } from 'utils/enums';
import GlobalStrings from 'utils/globalStrings';
import Loader from 'components/Loader/Loader';
import { useSearchParams } from 'react-router-dom';

const Filters = (props: {
  form: UseFormReturn<Inputs, any, any>;
  setStabilityPromptData: React.Dispatch<
    React.SetStateAction<
      | {
          sdxl_postfix: string;
          sdxl_negative_prompt: string;
        }
      | undefined
    >
  >;
  setAccentColor?: React.Dispatch<React.SetStateAction<string>>;
  accentColor: string;
}) => {
  const [data, setData] = useState();
  const [searchParams] = useSearchParams();
  const brand = searchParams.get('brand');
  const { request: getPrompts, pending } = useRequest({
    url: '/omniverse/prompts',
    method: 'get',
    onSuccess: (response) => {
      setData(response.data);
      props.setStabilityPromptData(response.data?.['Prompt Data'] as any);
      if (props.setAccentColor) {
        props.setAccentColor(response.data?.accentColor as string);
      }
    },
  });

  useEffect(() => {
    if (brand) {
      getPrompts();
    }
  }, [brand]);

  const labels = data?.['Product Data']['Product type']?.[props.form.watch('product')?.value] as any;
  const brands = [
    { value: 'coca-cola', name: 'Coca Cola', image: CocaCola },
    { value: 'fanta', name: 'Fanta', image: Fanta },
  ];
  const filters = [
    {
      label: GlobalStrings.Brand,
      icon: brandIcon,
      name: 'brand',
      type: 'grid',
      isSearchable: false,
      options: brands,
    },
    {
      label: GlobalStrings.Region,
      icon: regionIcon,
      name: 'region',
      type: 'linear',
      isSearchable: true,
      options: Object.keys(data?.['Region Data'] ?? []).map((item) => {
        return {
          value: item,
          name: item,
        };
      }),
    },
    {
      label: GlobalStrings.Product,
      icon: productIcon,
      name: 'product',
      type: 'grid',
      isSearchable: false,
      options: Object.keys(data?.['Product Data']['Product type'] ?? []).map((item) => {
        let productItem: { imagePath: string; Labels: string[] } | undefined;
        if (typeof data?.['Product Data']['Product type'][item] !== 'string') {
          productItem = data?.['Product Data']['Product type'][item];
        }
        return {
          value: item,
          name: item,
          image: productItem ? process.env.REACT_APP_API_URL + productItem.imagePath : '',
        };
      }),
    },
    {
      label: GlobalStrings.Label,
      icon: labelIcon,
      name: 'label',
      type: 'linear',
      isSearchable: false,
      options: labels?.Labels?.map((item: string) => {
        return {
          value: item,
          name: item,
        };
      }),
    },

    {
      label: GlobalStrings['Meal Type'],
      icon: mealTypeIcon,
      name: 'mealType',
      type: 'linear',
      isSearchable: false,
      options: Object.keys(data?.['Region Data'][props.form.watch('region')?.value] ?? []).map((item) => {
        return {
          value: item,
          name: item,
        };
      }),
    },
  ];

  useEffect(() => {
    props.form.setValue('mealType', undefined);
    props.form.setValue('prompt', '');
    props.form.setValue('negativePrompt', '');
    props.form.setValue('secondPrompt', '');
  }, [props.form.watch('region')?.name]);

  useEffect(() => {
    const region = data?.['Region Data']?.[props.form.watch('region')?.value];
    const mealType: any = region?.[props.form.watch('mealType')?.value ?? ''];

    if (region !== undefined && mealType !== undefined) {
      if (props.form.watch('AIType')?.value === AI.STABILITY) {
        props.form.setValue('prompt', mealType?.prompt);
        props.form.setValue('secondPrompt', mealType?.sdxl_detailer);
      } else {
        props.form.setValue('prompt', '');
      }
      props.form.setValue('negativePrompt', mealType?.negative_prompt);
      props.form.setValue('map', mealType);
      props.form.clearErrors('prompt');
      props.form.clearErrors('negativePrompt');
    }
  }, [props.form.watch('mealType'), props.form.watch('AIType')]);

  return (
    <div className={styles.filters}>
      <p>{GlobalStrings.Filters}</p>

      <div className={styles.filters_container}>
        {filters.map((item) => {
          return (
            <Filter
              key={item.name}
              label={item.label}
              icon={item.icon}
              name={item.name as any}
              type={item.type as 'grid' | 'linear'}
              isSearchable={item.isSearchable}
              options={item.options}
              form={props.form}
              accentColor={props.accentColor}
            />
          );
        })}
      </div>
      {pending && <Loader />}
    </div>
  );
};

export default Filters;
