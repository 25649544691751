import React, { useEffect, useState } from 'react';
import styles from './CameraSettings.module.scss';
import classNames from 'classnames';
import { Filters } from 'components/RightSidebar/RightSidebar';
import GlobalStrings from 'utils/globalStrings';
import ReactSlider from 'react-slider';
import overHeadImage from 'assets/icons/cameraAngle/over_head.png';
import flatLayImage from 'assets/icons/cameraAngle/flat_lay.png';
import angleImage from 'assets/icons/cameraAngle/45_degree.png';

const CameraSettings = (props: {
  filters: Filters | undefined;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | Function;
  dataCameraSettings: any;
}) => {
  const [HDRIRotation, setHDRIRotation] = useState(props.filters?.cameraSettings?.rotation);
  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          cameraSettings: {
            ...prev?.cameraSettings,
            rotation: HDRIRotation,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [HDRIRotation]);
  type AngleValue = 'low' | 'medium' | 'high';
  const imageByAngle: Record<AngleValue, string> = {
    low: overHeadImage,
    medium: flatLayImage,
    high: angleImage,
  };
  const resetHandler = () => {
    const resetAngleOption =
      props.filters?.cameraSettings?.angles.map((option: any) => ({
        ...option,
        selected: option.value === props.dataCameraSettings.angle.default,
      })) || [];
    setHDRIRotation(props.dataCameraSettings.rotation.default);
    props.setFilters((prev: any) => {
      return {
        ...prev,
        cameraSettings: {
          ...prev.cameraSettings,
          angles: resetAngleOption,
          rotation: props.dataCameraSettings.rotation.default,
        },
      };
    });
  };
  const handleSelectAngle = (value: string) => {
    const updatedAngles = props.filters?.cameraSettings?.angles.map((angle) => ({
      ...angle,
      selected: angle.value === value,
    }));

    props.setFilters((prev: any) => ({
      ...prev,
      cameraSettings: {
        ...prev?.cameraSettings,
        angles: updatedAngles,
        angle: value,
      },
    }));
  };
  return (
    <div className={styles.container}>
      {props.filters?.cameraSettings?.angles && props.dataCameraSettings.angle && (
        <div className={styles.inner_container}>
          <p>{props.dataCameraSettings.angle.label}</p>
          <div className={styles.grid_container}>
            {props.filters?.cameraSettings?.angles.map((item) => {
              const buttonClassName = classNames(styles.item, {
                [styles.active]: item.selected,
              });

              return (
                <button key={item.value} className={buttonClassName} onClick={() => handleSelectAngle(item.value)}>
                  <img src={imageByAngle[item.value as AngleValue]} className="image" alt={item.value} />
                  <p>{item.value}</p>
                </button>
              );
            })}
          </div>
        </div>
      )}
      {props.dataCameraSettings?.rotation && (
        <div className={styles.inner_container}>
          <p>{props.dataCameraSettings.rotation.label}</p>
          <div className={styles.rotation}>
            <div className={styles.current_value}>{HDRIRotation ?? 0}°</div>
            <ReactSlider
              className={styles.slider}
              thumbClassName={styles.thumb}
              thumbActiveClassName={styles.active_thumb}
              trackClassName={styles.track}
              markClassName={styles.mark}
              renderThumb={(props) => (
                <div {...props}>
                  <span className={classNames(styles.left, styles.arrow)}></span>
                  <span className={styles.bullet}></span>
                  <span className={classNames(styles.right, styles.arrow)}></span>
                </div>
              )}
              onChange={(newValue) => setHDRIRotation(newValue)}
              value={HDRIRotation ?? 0}
              min={props.dataCameraSettings.rotation.min}
              max={props.dataCameraSettings.rotation.max}
              marks={props.dataCameraSettings.rotation.position_markers}
              renderMark={(props: any) => {
                const order = props?.key;
                return <span {...props}>{+order > 0 ? +order - 40 : order}°</span>;
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.buttons_container}>
        <button className={styles.btn} onClick={resetHandler}>
          {GlobalStrings.Reset}
        </button>
        <button className={styles.btn} onClick={() => props.setIsOpen(false)}>
          {GlobalStrings.OK}
        </button>
      </div>
    </div>
  );
};

export default CameraSettings;
