import React from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';
import './styles/styles.scss';
import config from './config';
import { Layout, RequireAuth } from './components';
import { AssetGeneration, Dashboard, Public, AssetLibrary } from 'views';
import CorsErrorModal from './CorsErrorModal';
import AuthRequiredModal from './AuthRequiredModal';
import Header from './components/Header/Header';

// @ts-ignore
const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);
  const navigate = useNavigate();

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect({
      originalUri: `${window.location.origin}/dashboard`,
    });
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/dashboard', window.location.origin), { replace: true });
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };
  if (oktaAuth) {
    return (
      <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
        {' '}
        <Header {...{ setCorsErrorModalOpen }} customAuthHandler={customAuthHandler} />
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
        <Routes>
          <Route path="login/callback" element={<LoginCallback />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<Outlet />}>
            <Route element={<Layout />}>
              {/* public routes */}
              <Route index element={<Public />} />
              {/* protected routes */}
              <Route element={<RequireAuth />}>
                <Route path="asset-generation" element={<AssetGeneration />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route path="asset-library" element={<AssetLibrary />} />
              </Route>
              <Route path="*" element={<Public />} />
            </Route>
          </Route>
        </Routes>
      </Security>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route element={<Layout />}>
            {/* public routes */}
            <Route index element={<Public />} />

            {/* protected routes */}
            <Route element={<RequireAuth />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="asset-library" element={<AssetGeneration />} />
            </Route>
            <Route path="*" element={<Public />} />
          </Route>
        </Route>
      </Routes>
    );
  }
};
export default App;
