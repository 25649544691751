import React, { useEffect, useMemo, useState } from 'react';
import { RightSidebar, Sidebar } from 'components';
import arrowIcon from 'assets/icons/arrow-icon.svg';
import ExportIcon from 'assets/icons/export.svg';
import Placeholder from 'assets/images/placeholder.png';
import styles from './AssetGeneration.module.scss';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import GlobalStrings from 'utils/globalStrings';
import { Filters } from 'components/RightSidebar/RightSidebar';
import { data } from 'utils/data';
import { getFromLocalStorage, checkTenantId } from '../../utils/globalFunctions';
import { useOktaAuth } from '@okta/okta-react';
import axios, { AxiosInstance } from 'axios';
import useRequest from 'hooks/useRequest';
import { enqueueSnackbar } from 'notistack';
import { ReactSVG } from 'react-svg';
interface DataExport {
  product_name: string;
  region_name: string;
  product_label: string;
  ai_type: string;
  meal_type: string;
  prompt: string;
  negative_prompt: string;
  timestamp: string;
}
interface ProductLightsSettings {
  rotation?: { default: number };
  left_exposure?: { default: number };
  left_temperature?: { default: number };
  right_exposure?: { default: number };
  right_temperature?: { default: number };
  front_exposure?: { default: number };
  front_temperature?: { default: number };
  rear_exposure?: { default: number };
  rear_temperature?: { default: number };
  top_exposure?: { default: number };
  top_temperature?: { default: number };
}
interface CameraSettings {
  angle: { label: string; values: any[]; default: string };
  rotation: { default: number };
}
interface LightsSettings {
  height: { default: number };
  y_rotation: { default: number };
  hdr_exposure: { default: number };
  key_exposure: { default: number };
  hdr_temperature: { default: number };
  key_temperature: { default: number };
  hdri_file_name: { label: string; default: any; options: any[] };
}
const AssetGeneration = () => {
  const [imageURL, setImageURL] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const user = getFromLocalStorage('user');
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const [dataStreaming, setDataStreaming] = useState('');
  const [retouchDisabled, setRetouchDisabled] = useState(true);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const [dataProductLightSettings, setDataProductLightSettings] = useState<ProductLightsSettings | null>(null);
  const [dataLightSettings, setDataLightSettings] = useState<LightsSettings | null>(null);
  const [dataCameraSettings, setDataCameraSettings] = useState<CameraSettings | null>(null);
  const [dataExport, setDataExport] = useState<DataExport>({
    product_name: '',
    region_name: '',
    product_label: '',
    ai_type: '',
    meal_type: '',
    prompt: '',
    negative_prompt: '',
    timestamp: '',
  });
  const [addAssetLibraryBody, setAddAssetLibraryBody] = useState({
    fileUrl: '',
    aiModel: 'omniverse',
    product: '',
    label: '',
    region: '',
    scene: '',
  });
  const [accentColor, setAccentColor] = useState('#f40000');
  const [searchParams] = useSearchParams();
  const brand = searchParams.get('brand');
  const { request: getPrompts } = useRequest({
    url: '/omniverse/prompts',
    method: 'get',
    onSuccess: (response) => {
      if (response.data.createModels.omniverse.controls.product_lights) {
        setDataProductLightSettings(response.data.createModels.omniverse.controls.product_lights);
      }
      setDataLightSettings(response.data.createModels.omniverse.controls.light);
      setDataCameraSettings(response.data.createModels.omniverse.controls.camera);
    },
  });
  useEffect(() => {
    if (brand) {
      getPrompts();
    }
  }, [brand]);
  const handleExport = () => {
    setExportModalIsOpen(true);
  };
  const tenantId = useMemo(() => {
    return checkTenantId(brand);
  }, [brand]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({
    dressing: data.defaultValues.dressing,
  });
  useEffect(() => {
    if (brand) {
      const newAngles =
        dataCameraSettings?.angle?.values?.map((value: string) => ({
          value,
          selected: value === dataCameraSettings?.angle?.default,
          available: true,
        })) || [];
      const newHdri = {
        label: dataLightSettings?.hdri_file_name?.label || '',
        default: dataLightSettings?.hdri_file_name?.default || null,
        options:
          dataLightSettings?.hdri_file_name?.options?.map((item: any) => ({
            value: item.value,
            label: item.label,
            selected: item.value === dataLightSettings.hdri_file_name?.default.value,
          })) || [],
      };
      setFilters({
        dressing: data.defaultValues.dressing,
        cameraSettings: {
          angles: newAngles,
          rotation: dataCameraSettings?.rotation?.default ?? 0,
        },
        lightSettings: {
          height: dataLightSettings?.height?.default || 0,
          y_rotation: dataLightSettings?.y_rotation?.default || 0,
          hdr_exposure: dataLightSettings?.hdr_exposure?.default || 0,
          key_exposure: dataLightSettings?.key_exposure?.default || 0,
          hdr_temperature: dataLightSettings?.hdr_temperature?.default || 0,
          key_temperature: dataLightSettings?.key_temperature?.default || 0,
          hdri_file_name: newHdri,
        },
        ...(dataProductLightSettings && {
          productLightsSettings: {
            productLightsRotation: dataProductLightSettings.rotation?.default || 0,
            productLightLeftExposure: dataProductLightSettings.left_exposure?.default || 0,
            productLightLeftTemperature: dataProductLightSettings.left_temperature?.default || 0,
            productLightRightExposure: dataProductLightSettings.right_exposure?.default || 0,
            productLightRightTemperature: dataProductLightSettings.right_temperature?.default || 0,
            productLightFrontExposure: dataProductLightSettings.front_exposure?.default || 0,
            productLightFrontTemperature: dataProductLightSettings.front_temperature?.default || 0,
            productLightRearExposure: dataProductLightSettings.rear_exposure?.default || 0,
            productLightRearTemperature: dataProductLightSettings.rear_temperature?.default || 0,
            productLightTopExposure: dataProductLightSettings.top_exposure?.default || 0,
            productLightTopTemperature: dataProductLightSettings.top_temperature?.default || 0,
          },
        }),
      });
    }
  }, [brand, dataProductLightSettings, dataLightSettings, dataCameraSettings]);
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: user ? `Bearer ${getFromLocalStorage('user')?.acccesToken}` : `Bearer ${accessToken}`,
      context: JSON.stringify(tenantId),
    },
  });
  const { request: getOmniverseImageUrl } = useRequest({
    url: '/omniverse/upload-image',
    method: 'post',
    onSuccess: (response) => {
      setFileUrl(response.data.images[0].imageUrl);
    },
  });
  const { request: addAssetLibrary } = useRequest({
    url: '/create/assets',
    method: 'post',
    onSuccess: (response) => {
      if (!response.data.error) {
        enqueueSnackbar('Asset added to library', {
          variant: 'success',
        });
        setAddAssetLibraryBody({
          fileUrl: '',
          aiModel: 'omniverse',
          product: '',
          label: '',
          region: '',
          scene: '',
        });
      } else {
        enqueueSnackbar(response.data.error, {
          variant: 'error',
        });
      }
    },
    body: { data: [addAssetLibraryBody] },
  });

  useEffect(() => {
    if (addAssetLibraryBody.fileUrl && addAssetLibraryBody.label) {
      addAssetLibrary();
    }
  }, [addAssetLibraryBody]);
  useEffect(() => {
    if (brand) {
      (async () => {
        try {
          const response = await axiosInstance.get<{ streamUrl: string }>(`/omniverse/get-streaming-url`);
          const streamUrl = response.data ? response.data.streamUrl : '';
          setDataStreaming(
            `${streamUrl}?token=${user ? `${getFromLocalStorage('user')?.acccesToken}` : `${accessToken}`}`
          );
        } catch (error) {
          console.error('Error:', error);
        }
      })();
    }
  }, [authState, brand]);
  useEffect(() => {
    if (fileUrl)
      setAddAssetLibraryBody({
        ...addAssetLibraryBody,
        fileUrl,
        product: dataExport.product_name || '',
        label: dataExport.product_label || '',
        region: dataExport.region_name || '',
        scene: dataExport.meal_type || '',
      });
  }, [dataExport, fileUrl]);

  return (
    <section className={styles.asset_generation}>
      <Sidebar
        setImageURL={setImageURL}
        filters={filters}
        setFilters={setFilters}
        dataExport={dataExport}
        setDataExport={setDataExport}
        setAccentColor={setAccentColor}
        accentColor={accentColor}
        setRetouchDisabled={setRetouchDisabled}
        getOmniverseImageUrl={getOmniverseImageUrl}
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <Link to="/dashboard" className={styles.back_button}>
            <img src={arrowIcon} alt="back icon" />
            <span>{GlobalStrings.Back}</span>
          </Link>
          <button className={styles.goToLibrary} onClick={() => navigate(`/asset-library?brand=${brand}`)}>
            Go To Asset Library
          </button>
        </div>
        <div
          className={`${styles.iframe_container} ${dataStreaming ? styles.iframe_container_export : styles.iframe_container_placeholder}`}
        >
          {dataStreaming ? (
            <>
              <iframe className={styles.iframe} src={dataStreaming} title="Streaming"></iframe>
              <div className={styles.exportIcon} onClick={handleExport}>
                <ReactSVG src={ExportIcon} />
              </div>
            </>
          ) : (
            <img src={Placeholder} className={styles.placeholder} />
          )}
        </div>
      </div>
      <RightSidebar
        setImageURL={setImageURL}
        imageURL={imageURL}
        filters={filters}
        setFilters={setFilters}
        dataExport={dataExport}
        accentColor={accentColor}
        retouchDisabled={retouchDisabled}
        assetFileUrl={fileUrl}
        exportModalIsOpen={exportModalIsOpen}
        setExportModalIsOpen={setExportModalIsOpen}
        dataProductLightSettings={dataProductLightSettings}
        dataLightSettings={dataLightSettings}
        dataCameraSettings={dataCameraSettings}
      />
    </section>
  );
};

export default AssetGeneration;
