export const setToLocalStorage = (key: string, value: string | Object) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key');
  }

  if (typeof value === 'object') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    localStorage.setItem(key, value);
  }
};

export const getFromLocalStorage = (key: string) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key');
  }

  try {
    return JSON.parse(localStorage.getItem(key) ?? '');
  } catch {
    return localStorage.getItem(key);
  }
};

export const deleteFromLocalStorage = (key: string) => {
  if (!key || typeof key !== 'string') {
    throw new Error('Invalid key');
  }

  localStorage.removeItem(key);
};

export const checkTenantId = (brand: string | null) => {
  const tenants = process.env.REACT_APP_TENANTS_ID ? JSON.parse(process.env.REACT_APP_TENANTS_ID) : {};
  if (brand && tenants[brand]) {
    return tenants[brand];
  }
};

export const generateSeed = () => {
  const array = new Uint32Array(2);
  window.crypto.getRandomValues(array);
  return array[0] * 2 ** 32 + array[1];
};
