import React, { useEffect, useState } from 'react';
import styles from './LightSettings.module.scss';
import { Filters } from 'components/RightSidebar/RightSidebar';
import GlobalStrings from 'utils/globalStrings';
import ReactSlider from 'react-slider';
import sphere from 'assets/images/sphere.svg';
import hdriPlaceholder from 'assets/images/HDRI_placeholder.png';
import { data } from 'utils/data';
import classNames from 'classnames';

const renderThumb = (props: any, state: any) => (
  <div {...props}>
    <div className={styles.current_value}>{state.valueNow}</div>
  </div>
);

const GenerateSliders = (props: {
  data: {
    id: number;
    label: string;
    renderThumb: (props: any, state: any) => React.JSX.Element;
    onChange: (newValue: any) => void;
    value: number;
    min: number;
    max: number;
    marks: number[];
    step: number;
    renderMark: (props: any) => React.JSX.Element;
    withNumbers: boolean;
  }[];
  label?: string;
  children?: React.JSX.Element;
}) => {
  return (
    <div className={styles.inner_container}>
      {props.label && <p className={styles.slider_label}>{props.label}</p>}
      {props.children}
      {props.data.map((item) => {
        return (
          <div key={item.id}>
            <p className={styles.slider_label}>{item.label}</p>

            <div
              className={classNames(styles.slider_container, {
                [styles.with_numbers]: item.withNumbers,
              })}
            >
              <ReactSlider
                className={styles.slider}
                thumbClassName={styles.thumb}
                thumbActiveClassName={styles.active_thumb}
                trackClassName={styles.track}
                markClassName={styles.mark}
                renderThumb={item.renderThumb}
                onChange={item.onChange}
                value={item.value}
                min={item.min}
                step={item.step}
                max={item.max}
                marks={item.marks}
                renderMark={item.renderMark}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const LightSettings = (props: {
  filters: Filters | undefined;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>> | Function;
  dataLightSettings: any;
}) => {
  const [keyLightExposure, setKeyLightExposure] = useState(props.filters?.lightSettings?.key_exposure);
  const [HDRILightExposure, setHDRILightExposure] = useState(props.filters?.lightSettings?.hdr_exposure);
  const [keyLight, setKeyLight] = useState(props.filters?.lightSettings?.key_temperature);
  const [HDRILighting, setHDRILighting] = useState(props.filters?.lightSettings?.hdr_temperature);
  const [keyLightHeight, setKeyLightHeight] = useState(props.filters?.lightSettings?.height);
  const [keyLightRotation, setkeyLightRotation] = useState(props.filters?.lightSettings?.y_rotation);
  const [hdri, setHdri] = useState(
    props.filters?.lightSettings?.hdri_file_name?.options.find((h: any) => h.selected)?.value ?? ''
  );

  const keyLightExposureOptions = [
    ...(props.dataLightSettings?.key_exposure
      ? [
          {
            id: 1,
            label: props.dataLightSettings.key_exposure.label,
            renderThumb,
            onChange: (newValue: any) => setKeyLightExposure(newValue),
            value: keyLightExposure ?? 0,
            min: props.dataLightSettings.key_exposure.min,
            max: props.dataLightSettings.key_exposure.max,
            step: props.dataLightSettings.key_exposure.step,
            marks: [props.dataLightSettings.key_exposure.min, props.dataLightSettings.key_exposure.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataLightSettings?.hdr_exposure
      ? [
          {
            id: 2,
            label: props.dataLightSettings.hdr_exposure.label,
            renderThumb,
            onChange: (newValue: any) => setHDRILightExposure(newValue),
            value: HDRILightExposure ?? 0,
            min: props.dataLightSettings.hdr_exposure.min,
            max: props.dataLightSettings.hdr_exposure.max,
            step: props.dataLightSettings.hdr_exposure.step,
            marks: [props.dataLightSettings.hdr_exposure.min, props.dataLightSettings.hdr_exposure.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return <span {...props}>{+order > 0 ? +order : order}</span>;
            },
            withNumbers: true,
          },
        ]
      : []),
  ];
  const temperatureSliders = [
    ...(props.dataLightSettings?.key_temperature
      ? [
          {
            id: 1,
            label: props.dataLightSettings.key_temperature.label,
            renderThumb,
            onChange: (newValue: any) => setKeyLight(newValue),
            value: keyLight ?? data.defaultValues.lightSettings.keyLight,
            min: props.dataLightSettings.key_temperature.min,
            max: props.dataLightSettings.key_temperature.max,
            step: props.dataLightSettings.key_temperature.step,
            marks: [props.dataLightSettings.key_temperature.min, props.dataLightSettings.key_temperature.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return (
                <span {...props}>
                  {+order > 3000 ? +order - 800 : order}
                  {'K'}
                </span>
              );
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataLightSettings?.hdr_temperature
      ? [
          {
            id: 2,
            label: props.dataLightSettings.hdr_temperature.label,
            renderThumb,
            onChange: (newValue: any) => setHDRILighting(newValue),
            value: HDRILighting ?? data.defaultValues.lightSettings.HDRILighting,
            min: props.dataLightSettings.hdr_temperature.min,
            max: props.dataLightSettings.hdr_temperature.max,
            step: props.dataLightSettings.hdr_temperature.step,
            marks: [props.dataLightSettings.hdr_temperature.min, props.dataLightSettings.hdr_temperature.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return (
                <span {...props}>
                  {+order > 3000 ? +order - 800 : order}
                  {'K'}
                </span>
              );
            },
            withNumbers: true,
          },
        ]
      : []),
  ];
  const keyLightOptions = [
    ...(props.dataLightSettings?.height
      ? [
          {
            id: 1,
            label: props.dataLightSettings.height.label,
            renderThumb,
            onChange: (newValue: any) => setKeyLightHeight(newValue),
            value: keyLightHeight ?? 0,
            min: props.dataLightSettings.height.min,
            max: props.dataLightSettings.height.max,
            step: props.dataLightSettings.height.step,
            marks: [props.dataLightSettings.height.min, props.dataLightSettings.height.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return (
                <span {...props}>
                  {+order > 0 ? +order - 20 : order}
                  {'°'}
                </span>
              );
            },
            withNumbers: true,
          },
        ]
      : []),
    ...(props.dataLightSettings?.y_rotation
      ? [
          {
            id: 2,
            label: props.dataLightSettings.y_rotation.label,
            renderThumb,
            onChange: (newValue: any) => setkeyLightRotation(newValue),
            value: keyLightRotation ?? 0,
            min: props.dataLightSettings.y_rotation.min,
            max: props.dataLightSettings.y_rotation.max,
            step: props.dataLightSettings.y_rotation.step,
            marks: [props.dataLightSettings.y_rotation.min, props.dataLightSettings.y_rotation.max],
            renderMark: (props: any) => {
              const order = props?.key;
              return (
                <span {...props}>
                  {+order > 0 ? +order - 40 : order}
                  {'°'}
                </span>
              );
            },
            withNumbers: true,
          },
        ]
      : []),
  ];

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          lightSettings: {
            ...prev?.lightSettings,
            key_exposure: keyLightExposure,
            hdr_exposure: HDRILightExposure,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [keyLightExposure, HDRILightExposure]);

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          lightSettings: {
            ...prev?.lightSettings,
            key_temperature: keyLight,
            hdr_temperature: HDRILighting,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [keyLight, HDRILighting]);

  useEffect(() => {
    const delay = setTimeout(() => {
      props.setFilters((prev: any) => {
        return {
          ...prev,
          lightSettings: {
            ...prev?.lightSettings,
            height: keyLightHeight,
            y_rotation: keyLightRotation,
          },
        };
      });
    }, 700);

    return () => clearTimeout(delay);
  }, [keyLightHeight, keyLightRotation]);

  const resetHandler = () => {
    setKeyLightExposure(props.dataLightSettings.key_exposure.default);
    setHDRILightExposure(props.dataLightSettings.hdr_exposure.default);
    setKeyLight(props.dataLightSettings.key_temperature.default);
    setHDRILighting(props.dataLightSettings.hdr_temperature.default);
    setKeyLightHeight(props.dataLightSettings.height.default);
    setkeyLightRotation(props.dataLightSettings.y_rotation.default);
    const resetHdriOptions = props.dataLightSettings.hdri_file_name.options.map((option: any) => ({
      ...option,
      selected: option.value === props.dataLightSettings.hdri_file_name.default.value,
    }));
    setHdri(props.dataLightSettings.hdri_file_name.default.value);
    props.setFilters((prev: any) => {
      return {
        ...prev,
        lightSettings: {
          key_exposure: props.dataLightSettings.key_exposure.default,
          hdr_exposure: props.dataLightSettings.hdr_exposure.default,
          key_temperature: props.dataLightSettings.key_temperature.default,
          hdr_temperature: props.dataLightSettings.hdr_temperature.default,
          height: props.dataLightSettings.height.default,
          y_rotation: props.dataLightSettings.y_rotation.default,
          hdri_file_name: {
            ...props.dataLightSettings.hdri_file_name,
            options: resetHdriOptions,
            default: props.dataLightSettings.hdri_file_name.default,
          },
        },
      };
    });
  };

  return (
    <div className={styles.container}>
      <GenerateSliders data={keyLightExposureOptions} label={GlobalStrings.Intensity} />
      <GenerateSliders data={temperatureSliders} label={GlobalStrings.Temperature} />
      <GenerateSliders data={keyLightOptions} label={GlobalStrings.Angle}>
        <div className={styles.image}>
          <img src={sphere} alt="angle" />
        </div>
      </GenerateSliders>

      <div className={styles.hdri}>
        <p>{GlobalStrings['Change HDRI']}</p>
        <select
          className={styles.select}
          value={hdri}
          onChange={(event) => {
            const selectedValue = event.target.value;
            props.setFilters((prev: any) => {
              const updatedOptions = prev.lightSettings.hdri_file_name.options.map((option: any) => ({
                ...option,
                selected: option.value === selectedValue,
              }));
              return {
                ...prev,
                lightSettings: {
                  ...prev.lightSettings,
                  hdri_file_name: {
                    ...prev.lightSettings.hdri_file_name,
                    options: updatedOptions,
                    default: updatedOptions.find((option: any) => option.selected),
                  },
                },
              };
            });
            setHdri(selectedValue);
          }}
        >
          {props.filters?.lightSettings?.hdri_file_name?.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className={styles.image}>
          <img src={hdriPlaceholder} alt="hdri" />
        </div>
      </div>

      <div className={styles.buttons_container}>
        <button className={styles.btn} onClick={resetHandler}>
          {GlobalStrings.Reset}
        </button>
        <button className={styles.btn} onClick={() => props.setIsOpen(false)}>
          {GlobalStrings.OK}
        </button>
      </div>
    </div>
  );
};

export default LightSettings;
